import { Injectable } from '@angular/core';
import { WeekDay, WeekDays } from '@index/enums';
import { GthEventItemModel } from '@sentinels/models';

@Injectable({
  providedIn: 'root',
})
export class RecurringEventsService {
  buildRecurringEventItems(recurringEvents: GthEventItemModel[]): GthEventItemModel[] {
    const duplicatedEvents: GthEventItemModel[] = [];

    recurringEvents.forEach((event) => {
      if (!event.recurring?.recurring) return;
      if (!event.recurring?.recurringTimes?.length) return;
      if (!event.recurring?.recurringEnd) return;

      const originalStartDate = new Date(event.dateStart);

      event.recurring.recurringTimes.forEach((recurringTime) => {
        let nextDate: Date = originalStartDate;
        while (nextDate < event.recurring.recurringEnd) {
          const dupeEventItem = event.copy;
          const dupeEventItemModel = new GthEventItemModel(dupeEventItem.id, dupeEventItem);
          nextDate = this.getNextDateForDayOfWeek(
            nextDate,
            recurringTime.dayOfWeek,
          );

          dupeEventItemModel.model.dateStart = nextDate;
          duplicatedEvents.push(dupeEventItemModel);
        }
      });
    });

    return duplicatedEvents;
  }

  getRecurringEventDates(recurringEvents: GthEventItemModel[]): Date[] {
    return this.buildRecurringEventItems(recurringEvents).map(
      (event) => new Date(event.dateStart),
    );
  }

  private getNextDateForDayOfWeek(startDate: Date, dayOfWeek: WeekDay): Date {
    const resultDate = new Date(startDate);
    const dayNum = this.weekDayToNum(dayOfWeek);
    const currentDate = resultDate.getDay();
    let distance = dayNum - currentDate;

    if (distance <= 0) {
      distance += 7;
    }

    resultDate.setDate(resultDate.getDate() + distance);
    return resultDate;
  }

  private weekDayToNum(dayOfWeek: WeekDay): number {
    switch (dayOfWeek) {
      case WeekDays.Monday:
        return 1;
      case WeekDays.Tuesday:
        return 2;
      case WeekDays.Wednesday:
        return 3;
      case WeekDays.Thursday:
        return 4;
      case WeekDays.Friday:
        return 5;
      case WeekDays.Saturday:
        return 6;
      case WeekDays.Sunday:
        return 0;
    }
  }
}
